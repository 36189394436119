.footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  position: relative;
}

.footer .footer-top {
  background-color: color-mix(in srgb, var(--background-color), white 5%);
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
  text-align: start;
}

.footer h4 {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
  text-align: start;
}

.footer h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--accent-color);
  bottom: 0;
  right: 0;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .copyright {
  padding: 30px 0;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 5px;
  font-size: 13px;
}

.footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: color-mix(in srgb, var(--default-color), transparent 90%);
  color: var(--contrast-color);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  background: var(--accent-color);
  text-decoration: none;
}
