/* Fonts */
:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway", sans-serif;
  --nav-font: "Raleway", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #2f3138; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #0e1b4d; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #f82249; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: rgba(
    255,
    255,
    255,
    0.65
  ); /* The default color of the main navmenu links */
  --nav-hover-color: #ffffff; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #f82249; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f2f2f3;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #000820;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #001553;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* Pulsating Play Button
  ------------------------------*/
.pulsating-play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--accent-color) 50%,
    color-mix(in srgb, var(--accent-color), transparent 75%) 52%
  );
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.pulsating-play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-play-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.pulsating-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.pulsating-play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.pulsating-play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-play-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* PHP Email Form Messages
  ------------------------------*/
.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
.page-title {
  --background-color: #000000;
  --heading-color: var(--contrast-color);
  --default-color: var(--contrast-color);
  color: var(--default-color);
  background-color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 160px 0 80px 0;
  text-align: center;
  position: relative;
}

.page-title:before {
  content: "";
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 50%
  );
  position: absolute;
  inset: 0;
}

.page-title h1 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 10px;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.page-title .breadcrumbs ol li + li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li + li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

/*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 92px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 76px;
  }
}

/*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Speakers Section
  --------------------------------------------------------------*/
.speakers {
  --default-color: #ffffff;
}

.speakers .member {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.speakers .member .member-info {
  position: absolute;
  padding: 15px 0;
  inset: auto 0 -44px 0;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.4s;
}

.speakers .member .member-info-content h4 {
  color: var(--contrast-color);
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 16px;
}

.speakers .member .member-info-content h4 a {
  color: var(--contrast-color);
}

.speakers .member .member-info-content h4 a:hover {
  color: var(--accent-color);
}

.speakers .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.speakers .member .social {
  text-align: center;
  padding-top: 10px;
}

.speakers .member .social a {
  transition: color 0.3s;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin: 0 4px;
  display: inline-block;
}

.speakers .member .social a:hover {
  color: var(--default-color);
}

.speakers .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.speakers .member:hover .member-info {
  bottom: 0;
}

/*--------------------------------------------------------------
  # Schedule Section
  --------------------------------------------------------------*/
.schedule {
  --background-color: color-mix(
    in srgb,
    var(--alt-background-color),
    transparent 95%
  );
}

.schedule .nav-tabs {
  text-align: center;
  margin: auto;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
}

.schedule .nav-tabs li {
  display: inline-block;
  margin-bottom: 0;
}

.schedule .nav-tabs a {
  border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: var(--heading-color);
  color: var(--contrast-color);
  padding: 10px 100px;
}

@media (max-width: 991px) {
  .schedule .nav-tabs a {
    padding: 8px 60px;
  }
}

@media (max-width: 767px) {
  .schedule .nav-tabs a {
    padding: 8px 50px;
  }
}

@media (max-width: 480px) {
  .schedule .nav-tabs a {
    padding: 8px 30px;
  }
}

.schedule .nav-tabs a.active {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.schedule .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}

@media (min-width: 991px) {
  .schedule .sub-heading {
    width: 75%;
  }
}

.schedule .tab-pane {
  transition: ease-in-out 0.2s;
}

.schedule .schedule-item {
  border-bottom: 1px solid
    color-mix(in srgb, var(--default-color), transparent 85%);
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}

.schedule .schedule-item time {
  padding-bottom: 5px;
  display: inline-block;
}

.schedule .schedule-item .speaker {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}

.schedule .schedule-item .speaker img {
  height: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
  transition: all ease-in-out 0.3s;
}

.schedule .schedule-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.schedule .schedule-item h4 span {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-weight: normal;
  font-size: 16px;
}

.schedule .schedule-item p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Venue Section
  --------------------------------------------------------------*/
.venue .container-fluid {
  margin-bottom: 3px;
}

.venue .venue-map iframe {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.venue .venue-info {
  /* background: url("/public/img/venue-info-bg.jpg") top center no-repeat; */
  background-size: cover;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

.venue .venue-info:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.venue .venue-info h3 {
  font-size: 36px;
  font-weight: 700;
  color: var(--contrast-color);
}

@media (max-width: 574px) {
  .venue .venue-info h3 {
    font-size: 24px;
  }
}

.venue .venue-info p {
  color: var(--contrast-color);
  margin-bottom: 0;
}

.venue .venue-gallery-container {
  padding-right: 12px;
}

.venue .venue-gallery {
  overflow: hidden;
  border-right: 3px solid var(--background-color);
  border-bottom: 3px solid var(--background-color);
}

.venue .venue-gallery img {
  transition: all ease-in-out 0.4s;
}

.venue .venue-gallery:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Hotels Section
  --------------------------------------------------------------*/
.hotels .card {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  position: relative;
  border-radius: 0;
}

.hotels .card .card-img {
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 0;
}

.hotels .card .card-img img {
  transition: 0.3s ease-in-out;
}

.hotels .card h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 20px;
}

.hotels .card a {
  color: var(--heading-color);
  transition: 0.3;
}

.hotels .card a:hover {
  color: var(--accent-color);
}

.hotels .card .stars {
  padding: 0 20px;
  margin-bottom: 5px;
}

.hotels .card .stars i {
  color: #ffc107;
}

.hotels .card p {
  padding: 0 20px;
  margin-bottom: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-style: italic;
  font-size: 15px;
}

.hotels .card:hover .card-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Gallery Section
  --------------------------------------------------------------*/
.gallery {
  overflow: hidden;
}

.gallery .swiper-wrapper {
  height: auto;
}

.gallery .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.gallery .swiper-pagination .swiper-pagination-bullet {
  background-color: var(--background-color);
  border: 1px solid var(--accent-color);
  width: 12px;
  height: 12px;
  opacity: 1;
}

.gallery .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.gallery .swiper-slide-active {
  text-align: center;
}

@media (min-width: 992px) {
  .gallery .swiper-wrapper {
    padding: 40px 0;
  }

  .gallery .swiper-slide-active {
    background: var(--background-color);
    border: 6px solid var(--accent-color);
    padding: 4px;
    z-index: 1;
    transform: scale(1.2);
    transition: none;
  }
}

/*--------------------------------------------------------------
  # Sponsors Section
  --------------------------------------------------------------*/
.sponsors .clients-wrap {
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 85%);
  border-left: 1px solid
    color-mix(in srgb, var(--default-color), transparent 85%);
}

.sponsors .client-logo {
  background-color: var(--surface-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid
    color-mix(in srgb, var(--default-color), transparent 85%);
  border-bottom: 1px solid
    color-mix(in srgb, var(--default-color), transparent 85%);
  overflow: hidden;
}

.sponsors .client-logo img {
  padding: 50px;
  max-width: 80%;
  transition: 0.3s;
}

@media (max-width: 640px) {
  .sponsors .client-logo img {
    padding: 30px;
    max-width: 50%;
  }
}

.sponsors .client-logo:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
.faq .faq-container .faq-item {
  background-color: var(--surface-color);
  position: relative;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  border-radius: 5px;
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.faq .faq-container .faq-item h3 .num {
  color: var(--accent-color);
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-active {
  background-color: color-mix(in srgb, var(--accent-color), transparent 97%);
  border-color: color-mix(in srgb, var(--accent-color), transparent 80%);
}

.faq .faq-container .faq-active h3 {
  color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

/*--------------------------------------------------------------
  # Buy Tickets Section
  --------------------------------------------------------------*/
.buy-tickets .pricing-item {
  background-color: var(--surface-color);
  box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  position: relative;
}

.buy-tickets .pricing-item h3 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 24px;
}

.buy-tickets .pricing-item h4 {
  font-size: 48px;
  color: var(--accent-color);
  font-weight: 400;
  font-family: var(--heading-font);
  margin-bottom: 25px;
  text-align: center;
}

.buy-tickets .pricing-item h4 sup {
  font-size: 28px;
}

.buy-tickets .pricing-item h4 span {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 18px;
}

.buy-tickets .pricing-item ul {
  padding: 20px 0;
  list-style: none;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  text-align: left;
  line-height: 20px;
}

.buy-tickets .pricing-item ul li {
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.buy-tickets .pricing-item ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.buy-tickets .pricing-item ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.buy-tickets .pricing-item ul .na i {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.buy-tickets .pricing-item ul .na span {
  text-decoration: line-through;
}

.buy-tickets .buy-btn {
  color: var(--contrast-color);
  background-color: var(--accent-color);
  border: 2px solid var(--accent-color);
  display: inline-block;
  padding: 12px 40px;
  border-radius: 4px;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
  font-family: var(--heading-font);
}

.buy-tickets .buy-btn:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.buy-tickets .featured {
  background: var(--accent-color);
}

.buy-tickets .featured h3,
.buy-tickets .featured h4,
.buy-tickets .featured h4 span,
.buy-tickets .featured ul,
.buy-tickets .featured ul .na,
.buy-tickets .featured ul i,
.buy-tickets .featured ul .na i {
  color: var(--contrast-color);
}

.buy-tickets .featured .buy-btn {
  background: var(--accent-color);
  color: var(--contrast-color);
  border-color: var(--contrast-color);
}

.buy-tickets .featured .buy-btn:hover {
  background: color-mix(in srgb, var(--accent-color) 90%, black 15%);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
.contact .info-item {
  background-color: var(--surface-color);
  padding: 20px 0 30px 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .info-item i {
  font-size: 20px;
  color: var(--accent-color);
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border: 2px dotted color-mix(in srgb, var(--accent-color), transparent 40%);
}

.contact .info-item h3 {
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  background-color: var(--surface-color);
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: var(--surface-color);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type="text"]::placeholder,
.contact .php-email-form input[type="email"]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type="submit"] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
  # Events Section
  --------------------------------------------------------------*/
.events .details h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.events .details .social {
  margin-bottom: 15px;
}

.events .details .social a {
  background: color-mix(in srgb, var(--default-color), transparent 92%);
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  line-height: 1;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.events .details .social a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.events .details .social a i {
  font-size: 16px;
  line-height: 0;
}

.events .details p {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 15px;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}

/*--------------------------------------------------------------
  # IRANSans Section
  --------------------------------------------------------------*/
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Bold.eot");
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../public/fonts/iransans/woff2/IRANSansWeb_Bold.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../public/fonts/iransans/woff/IRANSansWeb_Bold.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../public/fonts/iransans/ttf/IRANSansWeb_Bold.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Medium.eot");
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../public/fonts/iransans/woff2/IRANSansWeb_Medium.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../public/fonts/iransans/woff/IRANSansWeb_Medium.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../public/fonts/iransans/ttf/IRANSansWeb_Medium.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Light.eot");
  src: url("../public/fonts/iransans/eot/IRANSansWeb_Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../public/fonts/iransans/woff2/IRANSansWeb_Light.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../public/fonts/iransans/woff/IRANSansWeb_Light.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../public/fonts/iransans/ttf/IRANSansWeb_Light.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url("../public/fonts/iransans/eot/IRANSansWeb_UltraLight.eot");
  src: url("../public/fonts/iransans/eot/IRANSansWeb_UltraLight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */
      url("../public/fonts/iransans/woff2/IRANSansWeb_UltraLight.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../public/fonts/iransans/woff/IRANSansWeb_UltraLight.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../public/fonts/iransans/ttf/IRANSansWeb_UltraLight.ttf")
      format("truetype");
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url("../public/fonts/iransans/eot/IRANSansWeb.eot");
  src: url("../public/fonts/iransans/eot/IRANSansWeb.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../public/fonts/iransans/woff2/IRANSansWeb.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../public/fonts/iransans/woff/IRANSansWeb.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../public/fonts/iransans/ttf/IRANSansWeb.ttf") format("truetype");
}

body {
  font-family: IRANSans !important;
  font-weight: 300;
  background-color: #e2e2e2;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  font-family: IRANSans !important;
}
h1 {
  font-weight: bold;
}
.wrapper {
  max-width: 900px;
  margin: 0 auto;
}
.ltr {
  direction: ltr;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-small {
  font-size: 0.8em;
}
.text-xsmall {
  font-size: 0.6em;
}
.text-large {
  font-size: 1.2em;
}
.text-xlarge {
  font-size: 1.4em;
}
.text-underline {
  text-decoration: underline;
}
.text-ultralight {
  font-weight: 200;
}
.text-light {
  font-weight: 300;
}
.text-regular {
  font-weight: normal;
}
.text-medium {
  font-weight: 500;
}
.text-bold {
  font-weight: bold;
}
blockquote {
  font-weight: 500;
  padding: 10px;
  border: 1px dashed #666666;
}

.mainbox {
  width: 100%;
  background-color: #efefef;
  display: table;
  margin-bottom: 30px;
  border-right: 8px solid #ffff33;
}

.mainboxnegativ {
  width: 100%;
  background-color: #000000;
  display: table;
  margin-bottom: 30px;
  border-right: 8px solid #ffff33;
  color: #f9f9f9;
}

.mainbox2 {
  font-size: 1em;
  width: 90%;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mainbox3 {
  width: 100%;
  background-color: #b8b8b8;
  display: table;
  margin-bottom: 30px;
  border-right: 8px solid #bd70ff;
}

.mainbox2negativ {
  font-size: 1em;
  color: #f9f9f9;
  background-color: #000000;
  padding-right: 20px;
}

.farsiparagraph {
  font-size: 1em;
  width: 47%;
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.englishparagraph {
  font-size: 1em;
  width: 47%;
  float: left;
  direction: ltr;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rightbox {
  width: 60%;
  padding-right: 20px;
  padding-left: 5px;
  float: right;
  margin-left: 10px;
  margin-bottom: 0px;
  min-width: 0px;
  background-color: #f7f7f7;
}

.titelbox {
  width: 60%;
  padding-right: 25px;
  padding-left: 0px;
  float: right;
  margin-left: 10px;
  margin-bottom: 0px;
  min-width: 0px;
  background-color: #f2f2f2;
  color: #4b4b4b;
}

.lefttbox {
  padding-right: 20px;
  padding-left: 4px;
  float: right;
  margin-bottom: 10px;
  min-width: 0px;
}

.alphabet {
  width: 35%;
  float: left;
  font-size: 21em;
  text-align: center;
  font-weight: 500;
  color: #999999;
}

.alphabet2 {
  width: 35%;
  float: left;
  direction: ltr;
  font-size: 1.6em;
  text-align: left;
  font-weight: 500;
  color: #333333;
  margin-top: 100px;
}
.footer {
  font-weight: 300;
  font-size: 0.7em;
  text-align: center;
  direction: ltr;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
