.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 100px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hero .hero-cover,
.hero img,
.hero video {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 70%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.hero h2 span {
  color: var(--accent-color);
}

.hero p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 10px 0 0 0;
  font-size: 24px;
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
  }
}

.hero .about-info {
  overflow: hidden;
  position: relative;
  padding: 60px 0 40px 0;
  z-index: 2;
  width: 100%;
}

.hero .about-info:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 50%);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.hero .about-info h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.hero .about-info h3 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
}

.hero .about-info p {
  font-size: 14px;
  margin-bottom: 20px;
  color: #fff;
}

.Typewriter {
  direction: rtl;
}